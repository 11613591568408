import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import { Article } from '@jelly/templates'

const DEFAULT_CHANNEL = {
	primary: {
		name: 'Business Basics',
		slug: 'business-basics',
	},
	sub: {
		name: 'Starting a Business',
		slug: 'starting-a-business',
	},
}

const Index = ({ data: { props, related }, pageContext: { canonical } }) => {
	// Take the path from thumbnail and assign it to meta.ogImage to fix the issue with social sharing for this template type.
	props.meta.ogImage = props.thumbnail.path
	props.meta.canonical = canonical
	props.canonical = canonical
	props.bestPicks = !!props.bestPicks?.bestPicksSection ? props.bestPicks.bestPicksSection.bestpicks : null

	props.author.slug = props.author.user?.slug || props.author.slug
	const authorImagePath = props.author.user?.imagePath.includes('undefined') ? null : props.author.user?.imagePath
	props.author.thumbnail = props.author.thumbnail || props.author.avatar || authorImagePath

	props.channels = !!props.channels ? props.channels : DEFAULT_CHANNEL
	props.breadcrumbs = !!props.breadcrumbs ? props.breadcrumbs : props.channels

	return <Article {...props} {...related} />
}

Index.propTypes = { data: object, pageContext: object }

export default Index

export const GatsbyQuery = graphql`
	query article($slug: String!, $preview: Boolean, $skipTOC: Boolean!) {
		# We always need to return props to ve injected on the component
		props: article(slug: $slug, preview: $preview, cache: false) {
			adsense
			adsenseMobile
			byLine
			canonical
			content
			docType
			guid
			lastModified: displayModified
			published
			structuredData
			subtitle
			template
			title
			type
			author {
				avatar
				bio
				# displayName is coming straight from mongo but we need "name" everywhere in the code
				name: displayName
				image
				slug
				social {
					facebook
					linkedin
					twitter
					website
				}
				status
				title
				type
				user {
					imagePath
					profile {
						company
						jobTitle
						website
					}
				}
			}
			breadcrumbs {
				primary {
					name
					slug
				}
				sub {
					name
					slug
				}
			}
			channels {
				primary {
					name
					slug
				}
				sub {
					name
					slug
				}
			}
			meta {
				robots
				description
				title
			}
			parsedContent {
				toc @skip(if: $skipTOC) {
					id
					title
				}
				json
			}
			resource {
				cta
				description
				path
				placeholder
				preview
			}
			sponsorship {
				sponsorType
				name: displayName
				thumbnail: avatar(height: 400, width: 400)
				bio
				slug
				status
				social {
					website
					linkedin
					twitter
					facebook
				}
			}
			thumbnail {
				path
				caption
				isBlurred
			}
			bestPicks {
				title
				widgetSection: section(type: SectionWidget) {
					... on SectionWidget {
						widget {
							widgetId
						}
					}
				}
				bestPicksSection: section(type: SectionBestPicks) {
					... on SectionBestPicks {
						bestpicks {
							fullTitle
							bullets
							isFeaturedBestPick
							isJumpLink
							tabTitle
							review {
								isReview
								adsense
								canonical
								review {
									fullReview
								}
								url
								docType
								title
								thumbnail {
									path
								}
								pricing {
									...pricingFields
								}
							}
						}
					}
				}
			}
		}
		related: article(slug: $slug, preview: $preview, cache: true) {
			relatedArticles {
				title
				canonical
				slug
				thumbnail
				dek
				lastModified
				published
				sponsored
			}
			relatedReviews {
				doc_type
				thumbnail
				canonical
				title
				dek
				slug
				lastModified
			}
			relatedSponsoredArticles {
				title
				canonical
				thumbnail
				dek
				lastModified
			}
		}
	}
`
